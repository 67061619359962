import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_URLS } from 'src/app/constants/api.urls';

@Injectable({
  providedIn: 'root'
})
export class AssistantService {

  constructor(private httpClient: HttpClient) { }

  getMsgOptions(): Observable<any> {
    return this.httpClient.get(API_URLS.getMsgOptions)
  }

  sendMessage(apiUrl: string, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.post(apiUrl, payload, { headers }).pipe(
      catchError(this.handleError)
    );
  }

  getClrSession(payload): Observable<any> {
    return this.httpClient.post(API_URLS.getClrSession, payload);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}`);
    }
    return throwError(`Empty or invalid response. Please try again later. `);
  }
}
