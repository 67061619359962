import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SOE_VIEWS } from 'src/app/features/home/components/soe/constants/soe.constants';


@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
})
export class MatTableComponent implements OnInit, AfterViewInit {
  @Input() displayedColumns: string[] = [];
  @Input() dataSource;
  @Input() headers: { [key: string]: string } = {}; // Dynamic headers for each column
  @Output() rowClicked = new EventEmitter<any>(); // Event emitter for row clicks
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() showPaginator: boolean = true;
  @Input() isStickyHeader: boolean = false;
  @Input() colorClassList: any;
  @Input() htmlView: string;
  soeViews = SOE_VIEWS;

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dataSource);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onRowClick(row: any) {
    this.rowClicked.emit(row);
  }

  getFontColor(cellColor: string): string {
    return ['red', 'yellow', 'green'].includes(cellColor)
      ? '#181830'
      : '#D7D7DC';
  }

  getColor(value: number): string {
    switch (this.htmlView) {
      case this.soeViews.SKU_PROJECTIONS: {
        if (parseInt(value.toString().replace(/,/g, ''), 10)) {
          return parseInt(value.toString().replace(/,/g, ''), 10) < 0
            ? this.colorClassList?.LESS_THAN_ZERO
            : this.colorClassList?.GREATER_THAN_ZERO;
        }
        break;
      }
      case this.soeViews.WAREHOUSE_UTILIZATION: {
        if (value.toString().includes('%')) {
          return Number(value.toString().replace('%', '')) > 90
            ? this.colorClassList?.OVERUTILIZED
            : Number(value.toString().replace('%', '')) < 50
              ? this.colorClassList?.UNDERUTILIZED
              : this.colorClassList.OPTIMALLY_UTILIZED;
        }
        break;
      }
    }
  }
}
