import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/constants/api.urls';
import { IPSPopUpRequest, ISchedulingDetailsDataResponse, ISchedulingDetailsRequestBody } from '../interfaces/scheduling-interface';

@Injectable({
  providedIn: 'root'
})
export class SchedulingDetailsService {

  constructor(private http: HttpClient) { }

  getProductionSchedulingFilters() {
    return this.http.get<any>(API_URLS.getProductionSchedulingFilters);
  }

  getProductionSchedulingData(selectedFilters: ISchedulingDetailsRequestBody): Observable<ISchedulingDetailsDataResponse> {
    return this.http.post<ISchedulingDetailsDataResponse>(API_URLS.getProductionSchedulingData, selectedFilters);
  }

  getPSPopUpData(payload: IPSPopUpRequest): Observable<any> {
    return this.http.post<any>(API_URLS.getPSPopUpData, payload);
  }

  getDirectShipmentData(): Observable<any> {
    const payload = {};
    return this.http.post(API_URLS.getDirectShipmentData, payload);
  }

  submitDSScenario(payload: any): Observable<any> {
    return this.http.post<any>(API_URLS.submitDSScenario, payload);
  }

  getPSAData(): Observable<any> {
    const payload = {};
    return this.http.post(API_URLS.getPSAData, payload);
  }

  submitPSAScenario(payload: any): Observable<any> {
    return this.http.post<any>(API_URLS.submitPSAScenario, payload);
  }

  getSkuDetailsData(payload: any): Observable<any> {
    return this.http.post<any>(API_URLS.getSkuDetailsData, payload);
  }
  
  allowsubmitPSPopup(): Observable<any> {
    return this.http.get<any>(API_URLS.allowSubmitPS);
  }

  submitProductionScheduling(payload: any): Observable<any> {
    return this.http.post<any>(API_URLS.submitProductionScheduling, payload)
  }
}
