export const FILL_RATE_LINE_CHART_NAME = 'Fill Rate';

export const MIN_X_AXIS_LIMIT = 14;

export const SERIES_CHART_COLORS = [
  //'#082C65',
  '#1777CF',
  '#AC6F0E',
  // '#DA5BB3',
  // '#9D7CD4',
  // '#E79ECD',
  //'#FF736A',
];

export const CAPACITY_CHART_COLORS = [
  '#4bac0e', //green
  '#AC6F0E',
]

export const PACKED_EXCESS_CAPACITY_DATA = [
  {
    type: 'column',
    color: '#0e4bac',
    name: 'Excess',
    data: [
      {
        name: 'p10',
        y: 4550,
      },
      {
        name: 'p11',
        y: 6770,
      },
      {
        name: 'p12',
        y: 5670,
      },
    ],
  },
  {
    type: 'column',
    color: '#4bac0e',
    name: 'planned',
    data: [
      {
        name: 'p10',
        y: 4560,
      },
      {
        name: 'p11',
        y: 56670,
      },
      {
        name: 'p12',
        y: 4678,
      },
    ],
  },
];

export const QTY_Y_AXIS_LABEL = 'Quantity';

export const CAPACITY_Y_AXIS_LABEL = 'Capacity';

export const INVENTORY_HEADERS = [
  'Material ID',
  'DIOH',
  'On Hand Inventory',
  'Average Weekly Forecast',
];

export const INVENTORY_HEADER_OBJ = {
  Material_ID: 'Material ID',
  DIOH: 'DIOH',
  OnHand: 'On Hand Inventory',
  Average_Weekly_Forecast: 'Average Weekly Forecast',
};

export const INVENTORY_COLUMNS = [
  "Material_ID",
  "DIOH",
  "OnHand",
  "Average_Weekly_Forecast"
]

export const QUANTITY_COLUMNS = [
  //'action',
  'SKU',
  'Qty at Risk',
  // 'Requested Delivery Date',
  // 'Next Production Batch',
  // 'Plant ID',
  // 'Available Production Capacity',
  'Planned Production'
];

export const QUANTITY_HEADERS = {
  action: 'SKU',
  SKU: 'Planned Production',
  'Planned Production': 'Planned Production',
};

export const PRODUCTION_SCHEDULING_GRAPH_NAME = {
  QTY_AT_RISK: 'Quantity at Risk',
  EXCESS_CAPACITY: 'Packed Vs Excess capacity',
  QTY_AT_RISK_DETAILS_TBL: 'Quantity at risk details table',
  INVENTORY_TABLE: 'Inventory_table',
};

export const QTY_RISK_DETAILS_HEADER = [
  'SKU',
  'Qty at Risk',
  // 'Requested Delivery Date',
  // 'Next Production Batch',
  // 'Plant',
  'Planned Production'
];


export interface ShippingTblData {
  orderId: string;
  sku: string;
  customerPriority: string;
  originalPlant: string;
  proposedPlant: string;
  quantitySatisfied: string;
  dueDate: string;
  currentRecoveryDate: string;
  proposedRecoveryDate: string;
  costImpact: string;
  childData: childData[];
}

export interface childData {
  orderId: string;
  sku: string;
  customerPriority: string;
  originalPlant: string;
  proposedPlant: string;
  quantitySatisfied: string;
  dueDate: string;
  currentRecoveryDate: string;
  proposedRecoveryDate: string;
  costImpact: string;
}

export const COLUMNS_TO_DISPLAY = [
  'expandIcon',
  'SKU',
  'OriginalPlant',
  'RecommendedPlant',
  'QuantitySatisfied',
  'DueDate',
  'CurrentRecoveryDate',
  'ProposedRecoveryDate',
  'costImpact',
  'approveReject'
];

export const CHILD_COLUMNS_TO_DISPLAY = [
  'expandIcon',
  'orderId',
  'sku',
  'customerPriority',
  'originalPlant',
  'proposedPlant',
  'quantitySatisfied',
  'dueDate',
  'currentRecoveryDate',
  'proposedRecoveryDate',
  'costImpact',
  'approveReject'
];

export const COLUMNS_HEADER_MAP = {
  'expandIcon': 'Details',
  'SKU': 'SKU',
  'OriginalPlant': 'Plant',
  'RecommendedPlant': 'DC',
  'QuantitySatisfied': 'Quantity Satisfied',
  'DueDate': 'Due Date',
  'CurrentRecoveryDate': 'Current Recovery Date',
  'ProposedRecoveryDate': 'Proposed Recovery Date',
  'CostImpact': 'Cost Impact',
  'approveReject': 'Actions'
};

export const PSA_VIEW = {
  PRODUCTION_SCHEDULING_APPROVALS: 'Production Scheduling Approvals',
};