<div class="tbl-container">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows
        class="mat-elevation-z8 shipping-details-tbl parent-table">
        <ng-container matColumnDef="expandIcon">
            <th mat-header-cell *matHeaderCellDef> Details </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="toggleRow(element); $event.stopPropagation()">
                    <mat-icon>
                        {{ expandedElement === element ? 'remove' : 'add' }}
                    </mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container *ngFor="let column of columnsToDisplay">
            <ng-container *ngIf="column !== 'approveReject' && column !== 'expandIcon'">
                <ng-container matColumnDef="{{ column }}">
                    <th mat-header-cell *matHeaderCellDef class="column-width">{{ columnHeaderMap[column] }}</th>
                    <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="approveReject">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row;" style="width: 6%;">
                <ng-container [ngSwitch]="row.Flag">
                    <!-- Rejected Status -->
                    <span *ngSwitchCase="0" class="status-rejected">Rejected</span>

                    <!-- Approved Status -->
                    <span *ngSwitchCase="1" class="status-approved">Approved</span>

                    <!-- Pending Status with Actions -->
                    <div *ngSwitchCase="2" class="actions-buttons d-flex">
                        <button mat-button (click)="approve(row,'approve')" class="approve-button" matTooltip="Approve"
                            [ngClass]="selectedAction[row.id] === 'approve' ? 'highlighted-action-btn' : 'approve-button' ">
                            <span class="material-symbols-outlined approve-icon">check_circle</span>
                        </button>

                        <button mat-button (click)="reject(row,'reject')" class="reject-button" matTooltip="Reject"
                            [ngClass]="selectedAction[row.id] === 'reject' ? 'highlighted-action-btn': 'reject-button'">
                            <span class="material-symbols-outlined reject-icon">cancel</span>
                        </button>
                    </div>
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
                <div class="element-detail" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="row.childData"
                        class="mat-elevation-z8 shipping-details-tbl child-table">
                        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
                            <th mat-header-cell *matHeaderCellDef class="column-width">
                                {{ column }}
                            </th>
                            <td mat-cell *matCellDef="let child">
                                {{ child[column] }}
                            </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let child; columns: columnsToDisplay"></tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" class="element-row"
            [class.example-expanded-row]="expandedElement === row"
            (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <div class="floating-button-container">
        <button mat-raised-button color="primary" (click)="submitSelections()" [disabled]="!isRecordSelected"
            aria-label="Submit Selections" class="submit-button">
            <b>Submit</b>
        </button>
    </div>
</div>