export interface ShippingTblData {
    shippingId: string;
    orderId: string;
    sku: string;
    origin: string;
    recommended: string;
    shippingQuantity: number;
    shippingLocation: string;
    dueDate: string;
    estShipDate: string;
    deliveryTimeImpact: string;
    logisticsCost: string;
    revenue: string;
    difference: string;
    childData: childData[];
}

export interface childData {
    shippingId: string;
    orderId: string;
    sku: string;
    origin: string;
    recommended: string;
    shippingQuantity: number;
    shippingLocation: string;
    dueDate: string;
    estShipDate: string;
    deliveryTimeImpact: string;
    logisticsCost: string;
    revenue: string;
    difference: string;
}

export const COLUMNS_TO_DISPLAY = [
    'expandIcon',
    'SKU',
    'Recommended',
    'ShippingQuantity',
    'ShippingLocation',
    'DueDate',
    'EstimatedShipDate',
    'LogisticsCost',
    'Revenue',
    'Difference',
    'approveReject'
];

export const CHILD_COLUMNS_TO_DISPLAY = [
    'expandIcon',
    'shippingId',
    'sku',
    'recommended',
    'shippingQuantity',
    'shippingLocation',
    'dueDate',
    'estShipDate',
    'deliveryTimeImpact',
    'logisticsCost',
    'revenue',
    'difference',
    'approveReject'
];

export const COLUMNS_HEADER_MAP = {
    'expandIcon': 'Details',
    'SKU': 'SKU',
    'Recommended': 'Recommended',
    'ShippingQuantity': 'Shipping Quantity',
    'ShippingLocation': 'Shipping Location',
    'DueDate': 'Due Date',
    'EstimatedShipDate': 'Estimated Ship Date',
    'LogisticsCost': 'Logistics Cost',
    'Revenue': 'Revenue',
    'Difference': 'Difference',
    'approveReject': 'Actions'
};

export const DIRECT_SHIPPING_VIEW = {
    DIRECT_SHIPPING: 'Direct Shipping',
};