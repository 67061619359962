import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { RedeploymentApprovalsService } from '../../../redeployment-approvals/services/redeployment-approvals.service';
import { Subscription } from 'rxjs';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import { MsalService } from '@azure/msal-angular';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { DIRECT_SHIPPING_VIEW } from '../../constants/direct-shipping.constant';
import { PSA_VIEW } from '../../constants/prod-scheduling.constant';

@Component({
  selector: 'app-expandable-table-with-action-btn',
  templateUrl: './expandable-table-with-action-btn.component.html',
  styleUrls: ['./expandable-table-with-action-btn.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ExpandableTableWithActionBtnComponent implements OnInit, OnDestroy {
  @Input() dataSource;
  @Input() expandedElement: any | null = null;
  @Input() columnsToDisplay;
  @Input() childColumnsToDisplay;
  @Input() columnHeaderMap;
  displayLoader: boolean = false;
  selectedButtons: { [key: string]: string } = {}; // Track user choices per row ('approved' or 'rejected')
  selectionStatus: { [key: string]: Array<{ status: string; data: any }> } = {};
  isRecordSelected: boolean = false;
  selectedAction: { [key: number]: string } = {};
  private submitAllowedSubscriptions$: Array<Subscription> = new Array<Subscription>();
  private submitSelectionsDsSubscriptions$: Array<Subscription> = new Array<Subscription>();
  private submitSelectionsPsaSubscriptions$: Array<Subscription> = new Array<Subscription>();
  isSubmitAllowed: boolean = false;
  @Output() submitAction = new EventEmitter<void>();
  @Input() htmlView: string;
  directShippingViews = DIRECT_SHIPPING_VIEW;
  prodSchedulingApprovalsViews = PSA_VIEW;

  constructor(
    private redeploymentApprovalsService: RedeploymentApprovalsService,
    private msalService: MsalService,
    private readonly toasterService: ToasterWrapperService,
    private schedulingDetailsService: SchedulingDetailsService,
  ) { }

  ngOnInit(): void { }

  toggleRow(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  isExpanded(row: any): boolean {
    return this.expandedElement === row;
  }

  approve(row: any, action: string): void {
    if (this.selectedAction[row.id] === action) {
      this.selectedAction[row.id] = null;
      // Remove the row from selectionStatus if present
      if (this.selectionStatus[row.SKU]) {
        this.selectionStatus[row.SKU] = this.selectionStatus[row.SKU].filter(
          (selectedRow: any) => selectedRow.data.id !== row.id
        );
        // Remove the SKU if no rows remain
        if (this.selectionStatus[row.SKU].length === 0) {
          delete this.selectionStatus[row.SKU];
        }
      }
      this.checkSubmitEnabled();
    } else {
      this.selectedAction[row.id] = action;
      // Ensure the SKU key exists and add the row
      if (!this.selectionStatus[row.SKU]) {
        this.selectionStatus[row.SKU] = [];
      }
      this.selectionStatus[row.SKU].push({ status: 'approved', data: row });
      this.selectedButtons[row.SKU] = 'approved';
      this.isRecordSelected = true;
      this.checkSubmitEnabled(); // Check if submit should be enabled
    }
  }


  reject(row: any, action: string): void {
    if (this.selectedAction[row.id] === action) {
      this.selectedAction[row.id] = null;
      // Remove the row from selectionStatus if present
      if (this.selectionStatus[row.SKU]) {
        this.selectionStatus[row.SKU] = this.selectionStatus[row.SKU].filter(
          (selectedRow: any) => selectedRow.data.id !== row.id
        );
        // Remove the SKU if no rows remain
        if (this.selectionStatus[row.SKU].length === 0) {
          delete this.selectionStatus[row.SKU];
        }
      }
      this.checkSubmitEnabled();
    } else {
      this.selectedAction[row.id] = action;
      // Ensure the SKU key exists and add the row
      if (!this.selectionStatus[row.SKU]) {
        this.selectionStatus[row.SKU] = [];
      }
      this.selectionStatus[row.SKU].push({ status: 'rejected', data: row });
      this.selectedButtons[row.SKU] = 'rejected';
      this.isRecordSelected = true;
      this.checkSubmitEnabled(); // Check if submit should be enabled
    }
  }

  checkSubmitEnabled(): void {
    this.isRecordSelected = Object.keys(this.selectionStatus).length > 0;
  }

  checkIfSubmitAllowed(): void {
    this.submitAllowedSubscriptions$.push(this.redeploymentApprovalsService.checkIfSubmitAllowed().subscribe({
      next: (response) => {
        if (response.allowEdits === true) {
          this.isSubmitAllowed = true;
        } else {
          this.isSubmitAllowed = false;
        }
      },
      error: (error) => {
        console.error('Error in getting the parameter edit access', error);
      },
    })
    );
  }

  submitSelections(): void {
    this.displayLoader = true;
    const results = Object.values(this.selectionStatus).flatMap((rows: any) =>
      rows.map((item: any) => ({
        ...item.data,
        Flag: item.status === 'approved' ? 1 : 0,
        child: item.data.child?.map((data: any) => ({
          ...data,
          Flag: item.status === 'approved' ? 1 : 0,
        })),
      }))
    );
    const payload = {
      user: this.msalService.instance.getActiveAccount().username,
      results,
    };
    this.submitTableData(payload);
  }


  submitTableData(payload) {
    switch (this.htmlView) {
      case this.directShippingViews.DIRECT_SHIPPING: {
        this.submitSelectionsDsSubscriptions$.push(this.schedulingDetailsService.submitDSScenario(payload)
          .subscribe(
            (response) => {
              this.selectionStatus = {};
              this.selectedButtons = {};
              this.isSubmitAllowed = false;
              this.toasterService.success(
                'Submitted successfully. Please wait for the request to be processed.'
              );
              this.submitAction.emit();
              this.isRecordSelected = false;
              this.displayLoader = false;
            },
            (error) => {
              this.displayLoader = false;
              this.toasterService.error(error?.error);
              console.error('Submission error:', error);
            }
          )
        );
        break;
      }
      case this.prodSchedulingApprovalsViews.PRODUCTION_SCHEDULING_APPROVALS: {
        this.submitSelectionsPsaSubscriptions$.push(this.schedulingDetailsService.submitPSAScenario(payload)
          .subscribe(
            (response) => {
              this.selectionStatus = {};
              this.selectedButtons = {};
              this.isSubmitAllowed = false;
              this.toasterService.success(
                'Submitted successfully. Please wait for the request to be processed.'
              );
              this.submitAction.emit();
              this.isRecordSelected = false;
              this.displayLoader = false;
            },
            (error) => {
              this.displayLoader = false;
              this.toasterService.error(error?.error);
              console.error('Submission error:', error);
            }
          )
        );
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.submitAllowedSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.submitSelectionsDsSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.submitSelectionsPsaSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }
}
