
export const ALERT_DETAILS_COLOR_CLASS = {
    ACT_ON: 'act_on',
    PREP_FOR: 'prep_for',
    MONITOR: 'monitor',
    WATCHLIST_DISMISS: 'watchlist_dismiss',
    DEFAULT: 'default'
};

export const ALERT_SUMMARY_TBL_VALUE = {
    ACT_ON: 'Act on',
    PREP_FOR: 'Prep for',
    MONITOR: 'Monitor',
    WATCHLIST_DISMISS: 'Watchlist/Dismiss',
    DEFAULT: 'Default'
};