<div *ngIf="displayLoader" class="spinner-overlay">
  <app-loader [isLoading]="displayLoader" [message]="loadingMessage"></app-loader>
</div>
<div *ngIf="isApiError" class="spinner-overlay">
  <app-loader [isLoading]="isApiError" [message]="apiErrorMessage"></app-loader>
</div>

<div class="main-container" *ngIf="loadView">
  <div class="row">
    <div class="col" *ngFor="let data of summaryCardData; let i = index">
      <div class="card card-stats mb-4 mb-xl-0 summary-card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="card-title">{{ data.name }}</span>
            </div>
          </div>
        </div>
        <div class="score-txt">
          <span class="score" [ngStyle]="{
                'background': gradientColors(i),
                'background-clip': 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }">
            {{ data.currentYear }}{{ data.unit || '' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-expandable-table-with-action-btn [dataSource]="storageDetailsTable" [columnsToDisplay]="columnsToDisplay"
    [columnHeaderMap]="columnHeaderMap" (submitAction)="onSubmitAction()"
    [htmlView]="viewPage"></app-expandable-table-with-action-btn>
</div>