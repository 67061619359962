<div class="dialog-title">
    <p class="title">LOCATION: {{ locationName }}</p>
    <button class="close-btn" mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div *ngIf="!isLoading">
    <mat-expansion-panel class="expansion-panel" *ngFor="let data of mapPointData; let i = index"
        (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle [@.disabled]="true">
        <mat-expansion-panel-header class="expansion-panel-header" [ngClass]="getCardClass(data.name)">
            <div class="action-details">
                <span class="name">{{data.noOfThreats}} {{data.name}}</span>
                <!-- <span class="threat-severity">{{ data.AlertType }}, {{data.ThreatSeverity}}</span> -->
            </div>
            <div class="expand-text">
                {{ panelOpenState ? 'Hide Details' : 'View Details' }}
                <mat-icon class="expand-icon" [class.rotated]="panelOpenState">expand_more</mat-icon>
            </div>
        </mat-expansion-panel-header>
        <div class="alert-details-container">
            <mat-expansion-panel class="expansion-panel-1" *ngFor="let data of data.details let i = index"
                (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false" hideToggle [@.disabled]="true">
                <mat-expansion-panel-header class="expansion-panel-header-1">
                    <table class="action-table" style="height: fit-content; width: fit-content;">
                        <tr>
                            <td colspan="2">
                                <span class="threat-id">Threat ID: {{data.ThreatID}}</span>
                                <span class="threat-family">{{data.ThreatFamily}}</span>
                                <span class="threat-severity">{{data.ThreatSeverity}}</span>
                                <span class="threat-severity">12</span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="threat-name">{{data.ThreatName}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="threat-date">{{data.ThreatStartDate}} TO {{data.ThreatEndDate}}
                                </div>
                                <div class="alert-duration">Duration: {{data.AlertDuration}} hours</div>
                                <div class="mitigation-level">Mitigation Level: {{data.MitigationLevel}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 70%;">
                                <mat-select class="alert-select" [(value)]="data.AlertType"
                                    (selectionChange)="onAlertTypeChange(element, $event)">
                                    <mat-option *ngFor="let alertType of data['ReassignAlertType']" [value]="alertType">
                                        {{ alertType }}
                                    </mat-option>
                                </mat-select>
                            </td>
                            <td style="width: 30%;">
                                <span class="expand-text">
                                    {{ panelOpenState1 ? 'Hide Details' : 'View Details' }}
                                    <mat-icon class="expand-icon"
                                        [class.rotated]="panelOpenState1">expand_more</mat-icon>
                                </span>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel-header>
                <div class="map-tabs">
                    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                        <mat-tab label="Lanes">
                            <table class="lanes-table">
                                <thead class="alert-thead">
                                    <tr class="table-thead">
                                        <th class="thead-title">Lane ID</th>
                                        <th class="thead-title">Category</th>
                                        <th class="thead-title">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of data.laneData; let i = index">
                                        <td class="mapCard-td">
                                            <span class="mapCard">{{data.laneId}}</span>
                                        </td>
                                        <td class="mapCard-td">
                                            <span class="mapCard">{{data.category}}</span>
                                        </td>
                                        <td class="mapCard-td">
                                            <span class="mapCard">{{data.score}}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!data.laneData?.length">
                                        <td class="no-record-data d-flex align-items-center" [attr.colspan]="3">
                                            No records found for the lanes!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-tab>
                        <mat-tab label="Assets">
                            <table class="assets-table">
                                <thead class="alert-thead">
                                    <tr class="table-thead">
                                        <th class="thead-title">Asset ID</th>
                                        <th class="thead-title">Asset Name</th>
                                        <th class="thead-title">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of data.assetData; let i = index">
                                        <td class="mapCard-td">
                                            <span class="mapCard">{{ data.assetId }}</span>
                                        </td>
                                        <td class="mapCard-td">
                                            <span class="mapCard">{{ data.assetName }}</span>
                                        </td>
                                        <td class="mapCard-td">
                                            <span class="mapCard">{{ data.score }}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!data.assetData?.length">
                                        <td class="no-record-data d-flex align-items-center" [attr.colspan]="3">
                                            No records found for the assets!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-expansion-panel>
</div>
<div *ngIf="!mapPointData?.length && !isLoading" class="no-record-data d-flex align-items-center">
    No records found for the location!
</div>
<div *ngIf="isLoading" class="spinner-overlay">
    <mat-spinner class="spinner"></mat-spinner>
</div>