import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SideNavService } from 'src/app/services/side-nav/side-nav.service';

@Component({
  selector: 'app-prod-scheduling-container',
  templateUrl: './prod-scheduling-container.component.html',
  styleUrls: ['./prod-scheduling-container.component.scss']
})
export class ProdSchedulingContainerComponent implements OnInit {
  currentTabIndex: number = 0;
  @Output() tabChanged = new EventEmitter<number>();

  constructor(private sideNavService: SideNavService) { }

  ngOnInit(): void {
    this.sideNavService.selectedIndex$.subscribe((index) => {
      this.currentTabIndex = index;
    });
  }

  getSelectedIndex(): number {
    return this.currentTabIndex;
  }
  getNewTab(newTab: number) {
    this.currentTabIndex = newTab;
  }
  ngOnDestroy(): void {
    this.currentTabIndex = 0;
  }

  onTabChange(event: any): void {
    const selectedTabIndex = event.index;
    this.sideNavService.setSelectedIndex(selectedTabIndex);
  }

}
