import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/constants/api.urls';

@Injectable({
  providedIn: 'root'
})
export class ResiliencyService {

  constructor(private http: HttpClient) { }

  getAlertDetailsData(): Observable<any> {
    return this.http.get<any>(API_URLS.getAlertDetailsData);
  }

  // getMapByThreatData(): Observable<any> {
  //   return this.http.get<any>(API_URLS.getMapByThreatData);
  // }

  getMapByThreatData(impactSeverity: string, threatId: string): Observable<any> {
    let params = new HttpParams();
    if (impactSeverity) {
      params = params.set('impactSeverity', impactSeverity);
    }
    if (threatId) {
      params = params.set('threatId', threatId);
    }
    return this.http.get<any>(API_URLS.getMapByThreatData2, { params });
  }

  getAlertCardData(action): Observable<any> {
    return this.http.get<any>(API_URLS.getAlertCardData + `?action=${action}`);
  }

  getMapAlertData(asset): Observable<any> {
    return this.http.get<any>(API_URLS.getMapAlertData + `?asset=${asset}`);
  }

  getAssetSummaryCard(action): Observable<any> {
    return this.http.get<any>(API_URLS.getAssetSummaryCard + `?action=${action}`);
  }
}