import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { Subscription } from 'rxjs';
import { IPSPopUpRequest } from '../../interfaces/scheduling-interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plant-details-model',
  templateUrl: './plant-details-model.component.html',
  styleUrls: ['./plant-details-model.component.scss']
})
export class PlantDetailsModelComponent implements OnInit, OnDestroy {
  displayLoader: boolean = false;
  showPaginator: boolean = false;
  modalTableData = { data: null, columns: null, headers: null };
  subscriptions$: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private schedulingDetailsService: SchedulingDetailsService,
    private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.getModalTableData();
  }

  getModalTableData() {
    this.displayLoader = true;
    this.modalTableData = { data: null, columns: null, headers: null };
    const payload: IPSPopUpRequest = {
      //materialID: this.data['materialID'],
      plantID: this.data['plantID'],
    };
    this.subscriptions$.push(
      this.schedulingDetailsService.getPSPopUpData(payload).subscribe(
        (response: any[]) => {
          if (response && response.length) {
            let plantsHeaders: { [key: string]: string } = {};
            const plantsColumns = Object.keys(response[0]);
            plantsColumns.forEach(column => {
              plantsHeaders[column] = column;
            });
            this.modalTableData = {
              data: this.transformData(response),
              columns: plantsColumns,
              headers: plantsHeaders,
            };
          }
          else {
            this.modalTableData = { data: [], columns: [], headers: [] };
            this.toasterService.info('No data available for the selected plant');
          }
          this.displayLoader = false;
        },
        (error) => {
          this.toasterService.error(
            'Error occurred while fetching api response'
          );
          this.displayLoader = false;
        }
      )
    );
  }

  calculateDateFieldsSum(row: any): number {
    return Object.keys(row)
      .filter((key) => /^\d{2}-\d{2}-\d{4}$/.test(key))
      .map((key) => Number(row[key]))
      .reduce((sum, value) => sum + value, 0);
  }

  transformData(inputData: any[]): any[] {
    return inputData.map((item, index) => {
      const cellColors: { [key: string]: string } = {};
      const materialID = item['Material ID'];
      const inventory = Number(item['Inventory']);
      const avgWeeklyForecast = Number(item['Avg Weekly Forecast']);
      const sumOfDateFields = this.calculateDateFieldsSum(item);
      const formatNumber = (value: any) => {
        const num = Number(value);
        return !isNaN(num) ? new Intl.NumberFormat('en-US').format(num) : value;
      };
      for (const key in item) {
        if (materialID === this.data.materialID) {
          // Condition 1: Selected Material ID => Green
          cellColors[key] = "green";
        } else if (avgWeeklyForecast === 0 && inventory > 0 && sumOfDateFields > 0) {
          // Condition 2: Avg Weekly Forecast = 0, Inventory > 0, Sum of Date > 0 => Red
          cellColors[key] = 'red';
        } else if (sumOfDateFields + inventory > avgWeeklyForecast) {
          // Condition 3: Sum of Date + Inventory < Avg Weekly Forecast => No color
          cellColors[key] = 'transparent'; // No color
        }
      }
      return {
        id: index + 1,
        cellColors: cellColors,
        ...Object.keys(item).reduce((acc, key) => {
          acc[key] = formatNumber(item[key]);
          return acc;
        }, {})
      };
    });
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(subscription => subscription.unsubscribe());
  }
}
