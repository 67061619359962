import { Component, OnInit } from '@angular/core';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { ISchedulingDetailsSummaryCard } from '../../interfaces/scheduling-interface';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import { CHILD_COLUMNS_TO_DISPLAY, COLUMNS_HEADER_MAP, COLUMNS_TO_DISPLAY, DIRECT_SHIPPING_VIEW, ShippingTblData } from '../../constants/direct-shipping.constant';

@Component({
  selector: 'app-direct-shipping',
  templateUrl: './direct-shipping.component.html',
  styleUrls: ['./direct-shipping.component.scss']
})
export class DirectShippingComponent implements OnInit {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  isLoading: boolean = true;
  displayLoader: boolean = false;
  isApiError: Boolean = false;
  loadView: boolean = false;
  storageDetailsTable: ShippingTblData[] = [];
  columnsToDisplay = COLUMNS_TO_DISPLAY;
  childColumnsToDisplay = CHILD_COLUMNS_TO_DISPLAY;
  columnHeaderMap = COLUMNS_HEADER_MAP;
  summaryCardData: ISchedulingDetailsSummaryCard[] = [];
  viewPage = DIRECT_SHIPPING_VIEW.DIRECT_SHIPPING;

  constructor(
    private schedulingDetailsService: SchedulingDetailsService,
    private readonly toasterService: ToasterWrapperService,
  ) { }

  ngOnInit(): void {
    this.getDirectShipmentData();
  }

  /**
  * function to get colors.
  * @param index card number as index
  * @returns color
  */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getDirectShipmentData(): void {
    this.displayLoader = true;
    this.loadView = false;
    this.isLoading = true;
    this.schedulingDetailsService.getDirectShipmentData().subscribe(
      (response) => {
        if (response) {
          this.summaryCardData = response?.summaryCard.data;
          this.storageDetailsTable = response?.storageDetailsTable.map((row: any, index: number) => ({
            id: index,
            ...row,
          }));
          this.isLoading = false;
          this.displayLoader = false;
          this.loadView = true;
        }
        (error) => {
          this.toasterService.error('Error occurred while fetching data');
          this.isLoading = false;
          this.displayLoader = false;
          this.loadView = true;
        }
      }
    )
  }

  onSubmitAction(): void {
    this.getDirectShipmentData();
  }
}
