import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResiliencyService } from '../../services/resiliency/resiliency.service';
import { ALERT_DETAILS_COLOR_CLASS, ALERT_SUMMARY_TBL_VALUE } from '../../constants/resiliency.constants';


@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit {
  panelOpenState = false;
  panelOpenState1 = false;
  locationName: string = this.data.name;
  mapPointData: { name: string; details: unknown; noOfThreats: any; }[];
  alertCardBorderColor = ALERT_DETAILS_COLOR_CLASS;
  isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<LocationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private resiliencyService: ResiliencyService
  ) { }

  ngOnInit(): void {
    this.resiliencyService.getMapAlertData(this.locationName).subscribe({
      next: (response) => {
        if (response) {
          this.mapPointData = Object.entries(response).map(([key, details]) => {
            const noOfThreats = Object.keys(details).length;
            return {
              name: key,
              details,
              noOfThreats,
            };
          });
        }
        this.isLoading = false;
      }
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getCardClass(name: string): string {
    let baseClass = '';
    switch (true) {
      case name.includes(ALERT_SUMMARY_TBL_VALUE.ACT_ON):
        baseClass = this.alertCardBorderColor.ACT_ON;
        break;
      case name.includes(ALERT_SUMMARY_TBL_VALUE.PREP_FOR):
        baseClass = this.alertCardBorderColor.PREP_FOR;
        break;
      case name.includes(ALERT_SUMMARY_TBL_VALUE.MONITOR):
        baseClass = this.alertCardBorderColor.MONITOR;
        break;
      case name.includes(ALERT_SUMMARY_TBL_VALUE.WATCHLIST_DISMISS):
        baseClass = this.alertCardBorderColor.WATCHLIST_DISMISS;
        break;
      default:
        baseClass = this.alertCardBorderColor.DEFAULT;
    }
    return baseClass;
  }
}
