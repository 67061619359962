import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts';
import worldMap from '@highcharts/map-collection/custom/world.geo.json';
import proj4 from 'proj4';
import MapModule from 'highcharts/modules/map';
import HighchartsMapChart from 'highcharts/modules/map';
import topology from '@highcharts/map-collection/custom/world.topo.json';
import { Router } from '@angular/router';
import { LocationDetailsComponent } from 'src/app/features/home/components/sc-resiliency/components/location-details/location-details.component';
import { MatDialog } from '@angular/material/dialog';

// Initialize the required Highcharts modules
MapModule(Highcharts);
HighchartsMapChart(Highcharts);

interface PlantData {
  nodeType: string;
  latitude: number;
  longitude: number;
  plantName: string;
  color: string;
  value: number;
  radius: number;
  fillColor: string;
  lineColor: string;
}

@Component({
  selector: 'map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.scss']
})
export class MapChartComponent implements OnInit {
  kpiType: any;
  @Input() mapLines: any = [];
  @Input() mapPoints: any = [];
  mapLinesData: any;
  mapPointsData: any;
  Highcharts: typeof Highcharts = Highcharts;
  @Input() seriesData = [];
  @Input() mapHeight = '600px';
  updateFlag = false;
  plantList = [];
  mapData = [];
  public show_nodes: boolean;
  showNodesRoutes = [
    '/home/meio-executive-summary',
    '/home/alerts-details',
    '/home/supply-impact'
  ];
  @Input() mapWidth = '600px';

  constructor(private _route: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.chartOptions.chart.height = this.mapHeight;
    this.show_nodes = this.showNodesRoutes.includes(
      this._route.url
    );
    this.updateChartData();
  }

  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: 'none',
      map: worldMap,
      proj4: proj4,
      height: this.mapHeight
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="color:{point.color};">' + '<br/>',
      pointFormat: '<span style="color:rgba(19, 19, 41, 1);">{point.name}: <b>{point.value}% </span></b><br/></span>',
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        //alignTo: 'spacingBox',
        //verticalAlign: 'bottom',
        align: 'right',
      },
    },
    plotOptions: {
      mappoint: {
        dataLabels: {
          enabled: false
        }
      },
      map: {
        allAreas: true,
        nullColor: '#5E5E81',
        borderColor: '#ffffff',
        borderWidth: 0.1
      }
    },
    series: [
      {
        dataLabels: {
          enabled: false,
        },
        allAreas: true,
        data: [],
      } as Highcharts.SeriesMapOptions,
      {
        type: 'mappoint',
        data: this.mapData,
        point: {
          events: {
            click: this.onPointClick.bind(this)
          }
        }
      }
    ]
  };

  chartOptions_data: Highcharts.Options = {
    chart: {
      backgroundColor: 'none',
      map: topology,
      proj4: proj4,
      height: this.mapHeight
    },
    title: {
      text: '',
      style: { display: 'none' }
    },
    subtitle: {
      text: '',
      style: { display: 'none' }
    },
    responsive: {
      rules: [{
        condition: {},
        chartOptions: {
          legend: { enabled: false }
        }
      }]
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: { enabled: false },
    tooltip: {
      headerFormat: '<span style="color:{point.color};"><br/>',
      pointFormat: '<span style="color:rgba(19, 19, 41, 1);">{point.name}</span>',
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: { align: 'right' },
    },
    plotOptions: {
      mappoint: {
        dataLabels: { enabled: false }
      },
      map: {
        allAreas: true,
        nullColor: '#5E5E81',
        borderColor: '#ffffff',
        borderWidth: 0.1
      },
      mapline: {
        dataLabels: { enabled: false }
      }
    },
    series: [
      { type: 'map', keys: ['hc-key', 'info'], name: 'world-map' },
      {
        type: 'mapline',
        data: this.mapLines,
        lineWidth: 1.5,
        dashStyle: 'Dash',
      },
      {
        type: 'mappoint',
        data: this.mapPoints
      },

    ]
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.seriesData || changes.mapLines || changes.mapPoints) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    if ((this.seriesData && this.seriesData.length > 0) || (this.mapLines && this.mapLines.length > 0) || (this.mapPoints && this.mapPoints.length > 0)) {
      this.mapData = this.seriesData.map((el: PlantData) => {
        return {
          lat: el.latitude,
          lon: el.longitude,
          name: el.plantName,
          color: el.color,
          value: el.value,
          marker: {
            radius: el.radius,
            fillColor: el.fillColor,
            lineColor: el.lineColor,
            lineWidth: 1
          }
        };
      });
      this.seriesData.find(el => {
        this.kpiType = el.kpiType;
      });

      this.chartOptions.series = [
        { data: [] } as Highcharts.SeriesMapOptions,
        {
          type: 'mappoint',
          marker: { radius: 7 },
          data: this.mapData
        }
      ];
      this.chartOptions_data.series = [
        { type: 'map', keys: ['hc-key', 'info'], name: 'world-map' },
        {
          type: 'mapline',
          data: this.mapLines.flatMap(node => {
            let geometry;
            if (node.geometry.type === 'LineString') {
              geometry = {
                type: 'LineString',
                coordinates: node.geometry.coordinates
              };
            } else if (node.geometry.type === 'Polygon') {
              geometry = {
                type: 'Polygon',
                coordinates: node.geometry.coordinates
              };
            }
            else if (node.geometry.type === 'MultiLineString') {
              geometry = {
                type: 'MultiLineString',
                coordinates: node.geometry.coordinates
              };
            }
            else if (node.geometry.type === 'MultiPolygon') {
              geometry = {
                type: 'MultiPolygon',
                coordinates: node.geometry.coordinates
              };
            }
            return {
              name: node.name,
              geometry: geometry,
              color: node.color
            };
          }),
          lineWidth: 1.5,
          dashStyle: 'Dash',
        },
        {
          type: 'mappoint',
          data: this.mapPoints,
          point: {
            events: {
              click: this.onTopoMapPointClick.bind(this)
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.value === 0) {
                return null;
              }
              return this.point.value;
            },
            style: {
              color: 'black',
              fontWeight: 'bold',
              fontSize: '14px'
            },
            align: 'center',
            verticalAlign: 'middle',
            padding: 5
          }
        }
      ];
      this.updateFlag = true;
    }
  }

  // Click event handler for map points
  onPointClick(event: Highcharts.PointClickEventObject): void {
    const { point } = event;
    // Check condition based on point name and kpiType
    if (point.name === 'Florida' && this.kpiType === 'OEE') {
      //const powerAppsUrl = 'https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Feur.safelink.emails.azure.net%2Fredirect%2F%3Fdestination%3Dhttps%253A%252F%252Fapps.powerapps.com%252Fplay%252Fe%252Fa3c669f6-ac2e-4e77-ad43-beab3e15bee7%252Fa%252F0cfbab10-b392-47e6-babd-ac0d8693fb05%253FtenantId%253D5b973f99-77df-4beb-b27d-aa0c70b8482c%2526source%253DAppSharedV3%2526hint%253Dbaef0ca9-e56e-4bfb-8770-70a7547b10ec%2526sourcetime%253D1718780244077%26p%3DbT1lZGQ2ODIxNS1lMzlkLTQxMzktYjg2Mi1hNjY5ZWE2MTI3NjAmdT1hZW8mbD1BcHBXZWJMYXVuY2hVcmk%253D&data=05%7C02%7CKokulakanth.Lalitha%40gds.ey.com%7C198749fdbf9b40f4816c08dc910992ee%7C5b973f9977df4bebb27daa0c70b8482c%7C0%7C0%7C638544717537974075%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=rBNxnBBKsg9uhrtdTr5TZ3pmLkeagMOOJgpNJeifBP4%3D&reserved=0';
      const powerAppsUrl = 'https://apps.powerapps.com/play/e/a3c669f6-ac2e-4e77-ad43-beab3e15bee7/a/8f32669b-c1c6-4066-8548-a73294c12d9c?tenantId=5b973f99-77df-4beb-b27d-aa0c70b8482c&sourcetime=1731991403481';
      this._route.navigate(['/home/iframe', { url: powerAppsUrl }]);
    } else {
      console.log(`Clicked on point: ${point.name}`);
    }
  }

  onTopoMapPointClick(event: Highcharts.PointClickEventObject): void {
    const { point } = event;

    // Example: Access the point's name, id, and other info
    const pointData = {
      name: point.name,
    };

    // Open the dialog and pass the point data
    this.dialog.open(LocationDetailsComponent, {
      data: pointData,
      maxWidth: '450px',
      width: '450px',
      maxHeight: '500px',
      panelClass: 'map-point-dialog-container'
    });
  }
}
