import { Component, OnInit } from '@angular/core';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { ISchedulingDetailsSummaryCard } from '../../interfaces/scheduling-interface';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import { CHILD_COLUMNS_TO_DISPLAY, COLUMNS_HEADER_MAP, COLUMNS_TO_DISPLAY, PSA_VIEW, ShippingTblData } from '../../constants/prod-scheduling.constant';

@Component({
  selector: 'app-prod-scheduling-approvals',
  templateUrl: './prod-scheduling-approvals.component.html',
  styleUrls: ['./prod-scheduling-approvals.component.scss']
})
export class ProdSchedulingApprovalsComponent implements OnInit {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  isLoading: boolean = true;
  displayLoader: boolean = false;
  isApiError: Boolean = false;
  loadView: boolean = false;
  summaryCardData: ISchedulingDetailsSummaryCard[] = [];
  expandedElement: ShippingTblData;
  detailsTable: any[] = [];
  columnsToDisplay = COLUMNS_TO_DISPLAY;
  childColumnsToDisplay = CHILD_COLUMNS_TO_DISPLAY;
  columnHeaderMap = COLUMNS_HEADER_MAP;
  viewPage = PSA_VIEW.PRODUCTION_SCHEDULING_APPROVALS;

  constructor(
    private schedulingDetailsService: SchedulingDetailsService,
    private readonly toasterService: ToasterWrapperService,
  ) { }

  ngOnInit(): void {
    this.getPSAData();
  }

  /**
   * function to get colors.
   * @param index card number as index
   * @returns color
   */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getPSAData(): void {
    this.displayLoader = true;
    this.loadView = false;
    this.isLoading = true;
    this.schedulingDetailsService.getPSAData().subscribe(
      (response) => {
        if (response) {
          this.summaryCardData = response?.summaryCard.data
          //remove this if the data is corrected in the api
          this.summaryCardData = this.summaryCardData.map((item: any) => {
            if (item.name === 'Available Production Capacity') {
              item.currentYear = '1.9 M';  // Set the required value for Available Production Capacity
            }
            if (item.name === 'Capacity Utilization') {
              item.currentYear = '98%';  // Set the required value for Capacity Utilization
            }
            return item;
          });
          //till here
          this.detailsTable = response?.detailsTable.map((row: any, index: number) => ({
            id: index,
            ...row,
          }));
          this.isLoading = false;
          this.displayLoader = false;
          this.loadView = true;
        }
        (error) => {
          this.toasterService.error('Error occurred while fetching data');
          this.isLoading = false;
          this.displayLoader = false;
          this.loadView = true;
        }
      }
    )
  }

  onSubmitAction(): void {
    this.getPSAData();
  }

}
