<div class="modal-content">
  <h2 style="color: white; text-align: center;">SKU Details</h2>

  <table *ngIf="tableData && tableData.length" border="1" style="border-collapse: collapse; width: 100%; text-align: center;">
    <thead>
      <tr>
        <th rowspan="2" style="color: white;">Plant</th>
        <ng-container *ngFor="let production of tableData[0].production">
          <th colspan="4" style="color: white;">{{ production.date }}</th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let production of tableData[0].production">
          <th style="color: white;">Qty at Risk</th>
          <th style="color: white;">Planned Production</th>
          <th style="color: white;">Remaining Capacity</th>
          <th style="color: white;">Updated Qty at Risk</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let plant of tableData; let plantIndex = index">
        <tr>
          <td style="color: white;">{{ plant.plant }}</td>

          <ng-container *ngFor="let production of plant.production; let prodIndex = index">
            <td style="color: white;">{{ production.qtyAtRisk }}</td>

            <!-- Editable Field with Dynamic Max Value -->
            <td>
              <span *ngIf="editingCell?.plantIndex !== plantIndex || editingCell?.prodIndex !== prodIndex"
                    (click)="startEditing(plantIndex, prodIndex)"
                    class="editable-text">
                {{ production.plannedProduction }}
              </span>

              <div *ngIf="editingCell?.plantIndex === plantIndex && editingCell?.prodIndex === prodIndex">
                <input 
                  type="number"
                  [value]="production.plannedProduction"
                  (blur)="stopEditing()"
                  (keyup.enter)="updatePlannedProduction($event.target.value, plantIndex, prodIndex)"
                  (keyup.esc)="stopEditing()"
                  class="editable-input"
                  [min]="0"
                  [max]="production.plannedProduction + production.updatedQtyAtRisk" 
                  [class.invalid-value]="isInvalidValue(plantIndex, prodIndex)"
                  title="Enter a value between 0 and {{ production.plannedProduction + production.updatedQtyAtRisk }}" />
                
                <!-- Dynamic Range Message -->
                <small [style.color]="isInvalidValue(plantIndex, prodIndex) ? 'red' : '#888'">
                  Enter a value between 0 and {{ production.plannedProduction + production.updatedQtyAtRisk }}.
                </small>
              </div>
            </td>

            <td style="color: white;">{{ production.remainingCapacity }}</td>
            <td style="color: white;">{{ production.updatedQtyAtRisk }}</td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div *ngIf="!tableData || !tableData.length" style="color: white; text-align: center;">
    No data available.
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 10px;">
    <button 
      mat-button 
      (click)="saveChanges()" 
      class="save-btn" 
      [disabled]="changedRows.length === 0 || !isAllValuesValid()">
      Save
    </button>
    <button mat-button (click)="close()" class="close-btn">Close</button>
  </div>
</div>
